/*-----------------------------------------------
|   Card
-----------------------------------------------*/
.card {
  --#{$prefix}card-bg: var(--#{$prefix}emphasis-bg);
  box-shadow: var(--#{$prefix}box-shadow);
}
.card-link {
  font-size: map_get($font-sizes, 10);
  font-weight: $font-weight-semibold;
}
.card-title {
  font-weight: $font-weight-semibold;
  font-size: map_get($font-sizes, 8);
}
.card-body:last-child {
  border-bottom-left-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;
}

/*-----------------------------------------------
|   Card spacer
-----------------------------------------------*/
.ms-ncard,
.mx-ncard,
.m-ncard {
  margin-left: -$card-spacer-x !important;
}
.me-ncard,
.mx-ncard,
.m-ncard {
  margin-right: -$card-spacer-x !important;
}
