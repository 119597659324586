/*-----------------------------------------------
|   Form
-----------------------------------------------*/
label {
  font-size: map_get($font-sizes, 10);
  font-weight: $font-weight-bold;
  letter-spacing: 0.02em;
}

.form-check-label {
  line-height: 1.45rem;
}

.form-label-nogutter {
  margin-bottom: 0;
  .form-check-label {
    margin-bottom: 0;
  }
}

/*-----------------------------------------------
|   Remove spinner from Input[type="number"]
-----------------------------------------------*/
.input-spin-none::-webkit-outer-spin-button,
.input-spin-none::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-spin-none[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

/*-----------------------------------------------
|   Theme Checkbox
-----------------------------------------------*/
.form-check {
  @each $color, $value in $theme-colors {
    &-input {
      &-#{$color} {
        border-color: $value !important;
        &:focus {
          box-shadow: 0
            0
            $input-btn-focus-blur
            $input-btn-focus-width
            rgba($value, $input-btn-focus-color-opacity);
        }
        &:checked {
          background-color: $value !important;
        }
        &.falcon-form-check-input {
          background-color: $value !important;
        }
      }
      &.falcon-form-check-input {
        background-color: $primary;
      }
    }
  }
}

.form-switch {
  .falcon-form-check-input.form-check-input {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
  }
}

.form-check-line-through {
  & + label {
    transition: $transition-base;
  }
  &:checked + label {
    color: $gray-500;
    text-decoration: line-through;
  }
}

// Floating Labels
.form-floating > label {
  line-height: 1.625rem;
}

input[type='file'].form-control-lg {
  line-height: 1.813rem;
}

textarea.form-control {
  height: auto;
}

option:disabled {
  color: var(--#{$prefix}gray-400);
}
