/* prettier-ignore */
@use 'sass:math';
@use 'sass:string';
$prefix: 'falcon-';

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
// scss-docs-start container-max-widths

// scss-docs-start container-max-widths
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1480px,
) !default;
// scss-docs-end container-max-widths

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
	xxs: 0,
	xs: 390px,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1180px,
	xxl: 1280px,
) !default;
// scss-docs-end grid-breakpoints
// $grid-breakpoints: (
// 	xs: 0,
// 	sm: 576px,
// 	md: 768px,
// 	lg: 992px,
// 	xl: 1200px,
// 	xxl: 1540px,
// ) !default;
// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacer-x1: 1.25rem;
$spacers: (
	0: 0,
	1: $spacer * 0.25,
	2: $spacer * 0.5,
	3: $spacer,
	4: $spacer * 1.8,
	5: $spacer * 3,
	6: $spacer * 4,
	7: $spacer * 5,
	8: $spacer * 7.5,
	9: $spacer * 10,
	10: $spacer * 12.5,
	11: $spacer * 15,
	x1: $spacer-x1,
) !default;
// scss-docs-end spacer-variables-maps

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $gray-800 !default;

// Min contrast ratio
$min-contrast-ratio: 2 !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-gutter-width: 2rem !default;

// Border
//
// Define border radius styles and more.
$border-color: $gray-300 !default;
$border-width: 1px !default;

// scss-docs-start border-styles
$border-styles: (
	dotted: 'dotted !important',
	dashed: 'dashed !important',
	none: 'none !important',
	hidden: 'hidden !important',
) !default;
// scss-docs-end border-styles

$disabled-border: transparent !default;

// scss-docs-start border-radius-variables
$border-radiuses: (
	null: var(--#{$prefix}border-radius),
	0: 0,
	1: var(--#{$prefix}border-radius-sm),
	2: var(--#{$prefix}border-radius),
	3: var(--#{$prefix}border-radius-lg),
	4: var(--#{$prefix}border-radius-xl),
	5: var(--#{$prefix}border-radius-xxl),
	circle: 50%,
	pill: var(--#{$prefix}border-radius-pill),
) !default;
// scss-docs-end border-radius-variables

$border-radius: 0.25rem !default;
$border-radius-lg: 0.375rem !default;

// Opacity
//
// scss-docs-start opacity-map
$opacities: (
	0: 0,
	25: 0.25,
	50: 0.5,
	75: 0.75,
	85: 0.85,
	100: 1,
) !default;
// scss-docs-end opacity-map

// Position
//
// Define the edge positioning anchors of the position utilities.

// scss-docs-start position-map
$positions: (static, absolute, relative, fixed, sticky) !default;
// scss-docs-end position-map

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-shadows: true !default;
$enable-gradients: false !default;
$enable-negative-margins: true !default;
$enable-dark-mode: true !default;

$component-active-bg: $primary !default;

// scss-docs-start box-shadow-variables
$box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07) !default;
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow-lg: 0 1rem 4rem rgba($black, 0.175) !default;
$box-shadow-inset: inset 0 1px 2px rgba($black, 0.075) !default;
// scss-docs-end box-shadow-variables

// Links
//
// Style anchor elements.
$link-decoration: none !default;
$link-hover-decoration: underline !default;
$link-shade-percentage: 20% !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
	'Segoe UI Symbol' !default;
$font-family-monospace: 'SFMono-Regular', Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;
$font-family-base: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !default;

$type-scale: 1.2 !default;
$font-size-base: 1rem !default;
$font-sizes: () !default;
$font-sizes: map-merge(
	(
		11: math.div(1, pow($type-scale, 2)) * $font-size-base,
		//11.11
		10: math.div(1, $type-scale) * $font-size-base,
		//13.33
		9: $font-size-base,
		//16
		8: pow($type-scale, 1) * $font-size-base,
		// 19.2
		7: pow($type-scale, 2) * $font-size-base,
		// 23.04
		6: pow($type-scale, 3) * $font-size-base,
		// 27.65
		5: pow($type-scale, 4) * $font-size-base,
		// 33.18
		4: pow($type-scale, 5) * $font-size-base,
		// 39.81
		3: pow($type-scale, 6) * $font-size-base,
		// 47.78
		2: pow($type-scale, 7) * $font-size-base,
		// 57.33
		1: pow($type-scale, 8) * $font-size-base,
		// 68.79
	),
	$font-sizes
);

$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1.2 !default;

$font-weight-thin: 100 !default;
$font-weight-lighter: 200 !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: 800 !default;
$font-weight-black: 900 !default;

$font-weights: (
	thin: $font-weight-thin,
	lighter: $font-weight-lighter,
	light: $font-weight-light,
	normal: $font-weight-normal,
	medium: $font-weight-medium,
	semibold: $font-weight-semibold,
	bold: $font-weight-bold,
	bolder: $font-weight-bolder,
	black: $font-weight-black,
) !default;

$h1-font-size: map_get($font-sizes, 4) !default;
$h2-font-size: map_get($font-sizes, 5) !default;
$h3-font-size: map_get($font-sizes, 6) !default;
$h4-font-size: map_get($font-sizes, 7) !default;
$h5-font-size: map_get($font-sizes, 8) !default;
$h6-font-size: map_get($font-sizes, 10) !default;

$headings-font-family: var(--#{$prefix}font-sans-serif) !default;
$headings-font-weight: $font-weight-medium !default;
$headings-color: var(--#{$prefix}secondary-color) !default;

// scss-docs-start display-headings
$display-font-sizes: (
	1: map_get($font-sizes, 1),
	2: map_get($font-sizes, 2),
	3: map_get($font-sizes, 3),
	4: map_get($font-sizes, 4),
	5: map_get($font-sizes, 5),
	6: map_get($font-sizes, 6),
) !default;

$display-font-weight: $font-weight-black !default;
$display-line-height: 1 !default;
// scss-docs-end display-headings

// scss-docs-start type-variables
$lead-font-size: $font-size-lg !default;
$lead-font-weight: $font-weight-normal !default;

$small-font-size: 75% !default;

$blockquote-font-size: $font-size-lg !default;

$hr-color: var(--#{$prefix}border-color) !default;
$hr-opacity: var(--#{$prefix}hr-opacity) !default;
// scss-docs-end type-variables

// Cards
//
// scss-docs-start card-variables
$card-spacer-y: 1.25rem !default;
$card-spacer-x: $spacer-x1 !default;
$card-border-width: 0px !default;
$card-border-radius: $border-radius-lg !default;
$card-border-color: rgba(var(--#{$prefix}emphasis-color-rgb), 0.125) !default;
$card-cap-bg: rgba($body-emphasis-color, 0) !default;
$card-cap-padding-y: $spacer !default;
$card-cap-padding-x: 1.25rem !default;
$card-bg: $white !default;
$card-bg-dark: tint-color($gray-1100, 2.9%) !default;
$card-title-color: $headings-color !default;
// scss-docs-end card-variables

// Accordion
//
// scss-docs-start accordion-variables
$accordion-icon-active-color: $body-color !default;
$accordion-bg: var(--#{$prefix}emphasis-bg) !default;
$accordion-border-color: rgba(var(--#{$prefix}emphasis-color-rgb), 0.125) !default;
$accordion-button-active-color: shade-color($primary, 10%) !default;
$accordion-button-active-bg: var(--#{$prefix}emphasis-bg) !default;
$accordion-button-color: var(--#{$prefix}accordion-btn-color-global) !default;
// scss-docs-end accordion-variables

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-cell-padding-y: 0.75rem !default;
$table-cell-padding-x: 0.75rem !default;

$table-bg: transparent !default;
$table-color: $gray-700 !default;
$table-border-color: var(--#{$prefix}body-bg) !default;

$table-active-color: var(--#{$prefix}body-color) !default;

$table-hover-color: var(--#{$prefix}body-color) !default;

$table-striped-order: even !default;
$table-striped-bg: var(--#{$prefix}gray-100) !default;

$table-group-separator-color: inherit !default;

$table-caption-color: $gray-500 !default;

$table-bg-scale: -80% !default;
// scss-docs-end table-variables

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start btn-falcon-variables
$btn-falcon-box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba($black, 0.07), 0 1px 2px 0 rgba($black, 0.08) !default;
$btn-falcon-hover-box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.1), 0 3px 9px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba($black, 0.08),
	0 1px 2px 0 rgba($black, 0.08) !default;
// scss-docs-end btn-falcon-variables

// scss-docs-start input-btn-variables
$input-btn-padding-y: 0.3125rem !default;
$input-btn-padding-x: 1rem !default;

$input-btn-padding-y-sm: 0.1875rem !default;
$input-btn-padding-x-sm: 0.75rem !default;

$input-btn-padding-y-lg: 0.375rem !default;
$input-btn-padding-x-lg: 1.25rem !default;

$input-plaintext-color: $gray-700 !default;
// scss-docs-end input-btn-variables

// scss-docs-start btn-variables
$btn-font-weight: $font-weight-medium !default;
$btn-focus-width: 0 !default;

$btn-color: $gray-700 !default;

$btn-disabled-opacity: 0.5 !default;
// scss-docs-end btn-variables

// Forms
//
// scss-docs-start form-input-variables
$input-bg: var(--#{$prefix}quaternary-bg) !default;
$input-disabled-bg: var(--#{$prefix}gray-200) !default;

$input-color: var(--#{$prefix}gray-900) !default;
$input-border-color: var(--#{$prefix}gray-300) !default;

$input-focus-border-color: var(--#{$prefix}input-focus-border-color-global) !default;

$input-placeholder-color: var(--#{$prefix}input-placeholder-color-global) !default;

$input-group-addon-bg: var(--#{$prefix}gray-200) !default;
// scss-docs-end form-input-variables

// scss-docs-start form-text-variables
$form-text-color: $gray-500 !default;
// scss-docs-end form-text-variables

// scss-docs-start form-label-variables
$form-label-font-size: map_get($font-sizes, 10) !default;
$form-label-font-weight: $font-weight-medium !default;
$form-label-margin-bottom: map-get($spacers, 2) !default;
// scss-docs-end form-label-variables

// scss-docs-start form-switch-variables
$form-switch-color: $gray-500 !default;
$form-switch-focus-color: tint-color($component-active-bg, 50%) !default;
// scss-docs-end form-switch-variables

// scss-docs-start form-check-variables
$form-check-margin-bottom: 0.34375rem !default;
$form-check-input-bg: transparent !default;
$form-check-input-border: 1px solid var(--#{$prefix}gray-400) !default;
// scss-docs-end form-check-variables

// scss-docs-start form-select-variables
$form-select-disabled-bg: var(--#{$prefix}gray-200) !default;
// scss-docs-end form-select-variables

// scss-docs-start form-file-variables
$form-file-button-bg: $gray-900 !default;
$form-file-button-hover-bg: $gray-900 !default;
$form-file-button-color: $gray-300 !default;
// scss-docs-start form-file-variables

// scss-docs-start form-range-variables
$form-range-track-bg: var(--#{$prefix}gray-300) !default;
$form-range-thumb-active-bg: lighten($component-active-bg, 35%) !default;
$form-range-thumb-disabled-bg: $gray-500 !default;
// scss-docs-end form-range-variables

// Z-index master list
//
// scss-docs-start zindex-stack
$zindex-sticky: 1015 !default;
// scss-docs-end zindex-stack

// Navbar
//
// scss-docs-start navbar-variables
$navbar-padding-y: map_get($spacers, 2) !default;
$navbar-padding-x: $spacer !default;

$top-nav-height: var(--#{$prefix}top-nav-height) !default;
$standard-nav-height: 3.5625rem !default;

$navbar-brand-font-size: map_get($font-sizes, 6) !default;
$navbar-brand-font-weight: $font-weight-bolder !default;

$navbar-light-color: #{rgba(var(--#{$prefix}emphasis-color-rgb), 0.55)} !default;
$navbar-light-hover-color: #{rgba(var(--#{$prefix}emphasis-color-rgb), 0.7)} !default;
$navbar-light-active-color: #{rgba(var(--#{$prefix}emphasis-color-rgb), 0.9)} !default;
$navbar-light-disabled-color: #{rgba(var(--#{$prefix}emphasis-color-rgb), 0.3)} !default;
$navbar-light-toggler-border-color: #{rgba(var(--#{$prefix}emphasis-color-rgb), 0.1)} !default;
$navbar-light-toggler-icon-bg: str-replace(
	url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#9da9bb' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M0 6h30M0 14h30M0 22h30'/%3E%3C/svg%3E"),
	'#',
	'%23'
) !default;

$navbar-dark-toggler-icon-bg: str-replace(
	url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#9da9bb' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M0 6h30M0 14h30M0 22h30'/%3E%3C/svg%3E"),
	'#',
	'%23'
) !default;
// scss-docs-end navbar-variables

// Navbar glass
//
$bg-navbar-glass: var(--#{$prefix}bg-navbar-glass) !default;

// Navs

// scss-docs-start nav-variables
$nav-link-color: null !default;
$nav-link-hover-color: null !default;
$nav-link-disabled-color: var(--#{$prefix}gray-600) !default;

$nav-tabs-border-color: var(--#{$prefix}border-color) !default;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color !default;
// scss-docs-end nav-variables

// Navbar vertical
//
// scss-docs-start navbar-vertical-variables
$navbar-vertical-breakpoints: mapReverse($grid-breakpoints) !default;
$navbar-vertical-width: 12.625rem !default;
$navbar-vertical-icon-width: 1.5rem !default;
$navbar-vertical-collapsed-width: 3.125rem !default;
$navbar-vertical-hover-width: 12.625rem !default;
$navbar-vertical-variation-width: 14.625rem !default;
$navbar-vertical-variation-collapsed-width: 4.125rem !default;
$navbar-vertical-link-font-size: $font-size-sm !default;
$navbar-vertical-dropdown-font-size: map-get($font-sizes, 10) !default;
$navbar-vertical-collapsed-hover-shadow: 0.625rem 0 0.625rem -0.5625rem rgba($black, 0.2) !default;
// scss-docs-end navbar-vertical-variables

// Navbar vertical styles
//
// scss-docs-start navbar-vertical-default-variables
$navbar-vertical-default-bg-color: var(--#{$prefix}bg-navbar-glass) !default;
$navbar-vertical-default-link-color: #{$gray-700} !default;
$navbar-vertical-default-link-hover-color: #{$gray-1000} !default;
$navbar-vertical-default-link-active-color: #{$primary} !default;
$navbar-vertical-default-link-disable-color: #{$gray-400} !default;
$navbar-vertical-default-hr-color: #{rgba($black, 0.08)} !default;
$navbar-vertical-default-scrollbar-color: #{rgba($gray-600, 0.3)} !default;
$navbar-vertical-default-label-color: var(--#{$prefix}gray-500) !default;
// scss-docs-end navbar-vertical-default-variables

// scss-docs-start navbar-vertical-inverted-variables
$navbar-vertical-inverted-bg-color: $gray-1000 !default;
$navbar-vertical-inverted-link-color: $gray-500 !default;
$navbar-vertical-inverted-link-hover-color: $gray-200 !default;
$navbar-vertical-inverted-link-active-color: $navbar-vertical-inverted-link-hover-color !default;
$navbar-vertical-inverted-link-disable-color: $gray-700 !default;
$navbar-vertical-inverted-hr-color: rgba($white, 0.08) !default;
$navbar-vertical-inverted-scrollbar-color: $gray-400 !default;
$navbar-vertical-inverted-label-color: $gray-700 !default;
// scss-docs-end navbar-vertical-inverted-variables

// scss-docs-start navbar-vertical-vibrant-variables
$navbar-vertical-vibrant-bg-image: linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2), url(../img/generic/bg-navbar.png) !default;
$navbar-vertical-vibrant-link-color: rgba($white, 0.75) !default;
$navbar-vertical-vibrant-link-hover-color: $white !default;
$navbar-vertical-vibrant-link-active-color: $navbar-vertical-vibrant-link-hover-color !default;
$navbar-vertical-vibrant-link-disable-color: rgba($white, 0.45) !default;
$navbar-vertical-vibrant-hr-color: rgba($white, 0.2) !default;
$navbar-vertical-vibrant-scrollbar-color: $gray-400 !default;
$navbar-vertical-vibrant-label-color: rgba($white, 0.4) !default;
// scss-docs-end navbar-vertical-vibrant-variables

// scss-docs-start navbar-vertical-card-variables
$navbar-vertical-card-shadow: $box-shadow !default;
$navbar-vertical-card-bg-color: $card-bg !default;
$navbar-vertical-card-link-color: var(--#{$prefix}gray-700) !default;
$navbar-vertical-card-link-hover-color: var(--#{$prefix}gray-900) !default;
$navbar-vertical-card-link-active-color: var(--#{$prefix}primary) !default;
$navbar-vertical-card-link-disable-color: var(--#{$prefix}gray-400) !default;
$navbar-vertical-card-hr-color: rgba($black, 0.08) !default;
$navbar-vertical-card-scrollbar-color: rgba($gray-600, 0.3) !default;
$navbar-vertical-card-label-color: var(--#{$prefix}gray-500) !default;
// scss-docs-end navbar-vertical-card-variables

// Dropdowns
//
// Dropdown menu container and contents.

// scss-docs-start dropdown-variables
$dropdown-font-size: map_get($font-sizes, 10) !default;
$dropdown-padding-y: map_get($spacers, 3) !default;
$dropdown-item-padding-y: map_get($spacers, 1) !default;
$dropdown-item-padding-x: map_get($spacers, 3) !default;
$dropdown-box-shadow: var(--#{$prefix}box-shadow) !default;

$dropdown-bg: var(--#{$prefix}dropdown-bg-global) !default;
$dropdown-color: var(--#{$prefix}gray-300) !default;
$dropdown-divider-bg: var(--#{$prefix}border-color) !default;
$dropdown-border-color: var(--#{$prefix}border-color) !default;

$dropdown-link-color: var(--#{$prefix}gray-900) !default;
$dropdown-link-hover-color: var(--#{$prefix}dropdown-link-hover-color-global) !default;
$dropdown-link-hover-bg: var(--#{$prefix}dropdown-link-hover-bg-global) !default;

$dropdown-link-disabled-color: var(--#{$prefix}gray-600) !default;
$dropdown-header-color: var(--#{$prefix}gray-600) !default;
// scss-docs-end dropdown-variables

// Pagination
//
// scss-docs-start pagination-variables
$pagination-padding-y: 0.5rem !default;

$pagination-color: var(--#{$prefix}emphasis-color) !default;
$pagination-bg: var(--#{$prefix}quaternary-bg) !default;
$pagination-border-color: var(--#{$prefix}gray-200) !default;

$pagination-focus-color: var(--#{$prefix}gray-700) !default;
$pagination-focus-bg: var(--#{$prefix}gray-100) !default;
$pagination-focus-box-shadow: none !default;

$pagination-hover-color: $white !default;
$pagination-hover-bg: $primary !default;
$pagination-hover-border-color: $primary !default;

$pagination-disabled-color: $pagination-focus-color !default;
$pagination-disabled-bg: $pagination-focus-bg !default;
$pagination-disabled-border-color: $pagination-border-color !default;
// scss-docs-end pagination-variables

// Tooltips
//
// scss-docs-start tooltip-variables
$tooltip-padding-y: 0.5rem !default;
$tooltip-font-size: map_get($font-sizes, 10) !default;
$tooltip-bg: $body-emphasis-color !default;
$tooltip-color: $white !default;
// scss-docs-end tooltip-variables

// Badges
//
// scss-docs-start badge-variables
$badge-font-weight: $font-weight-semibold !default;
$badge-padding-y: 0.355555em !default;
$badge-padding-x: 0.711111em !default;
// scss-docs-end badge-variables

// Modals
//
// scss-docs-start modal-variables
$modal-content-border-radius: $border-radius-lg !default;
$modal-content-bg: var(--#{$prefix}emphasis-bg) !default;
// scss-docs-end modal-variables

// List group
//
// scss-docs-start list-group-variables
$list-group-color: var(--#{$prefix}gray-800) !default;
$list-group-bg: var(--#{$prefix}list-group-bg-global) !default;
$list-group-border-color: var(--#{$prefix}border-color) !default;

$list-group-action-hover-color: var(--#{$prefix}gray-700) !default;
$list-group-hover-bg: var(--#{$prefix}body-bg) !default;

$list-group-disabled-color: var(--#{$prefix}gray-600) !default;
$list-group-disabled-bg: var(--#{$prefix}card-cap-bg) !default;

$list-group-action-active-bg: var(--#{$prefix}gray-200) !default;
// scss-docs-end list-group-variables

// Image thumbnails
//
// scss-docs-start thumbnail-variables
$thumbnail-bg: var(--#{$prefix}thumbnail-bg-global) !default;
$thumbnail-border-width: 3px !default;
$thumbnail-border-color: var(--#{$prefix}thumbnail-bg-global) !default;
// scss-docs-end thumbnail-variables

// Figures
//
// scss-docs-start figure-variables
$figure-caption-color: var(--#{$prefix}gray-600) !default;
// scss-docs-end figure-variables

// Breadcrumbs
//
// scss-docs-start breadcrumb-variables
$breadcrumb-padding-x: 0 !default;
$breadcrumb-margin-bottom: 0 !default;
$breadcrumb-bg: 'transparent' !default;
$breadcrumb-divider: quote('/') !default;
$breadcrumb-divider-color: $gray-600 !default;
$breadcrumb-active-color: $gray-600 !default;
// scss-docs-end breadcrumb-variables

// Carousel
//
// scss-docs-start carousel-variables
$carousel-transition-duration: 0.8s !default;
// scss-docs-end carousel-variables

// Spinners
//
// scss-docs-start spinner-variables
$spinner-width-sm: 1.35rem !default;
$spinner-height-sm: $spinner-width-sm !default;
// scss-docs-end spinner-variables

//
// Falcon Specific
//

$transparent-50: rgba(var(--#{$prefix}quaternary-bg-rgb), 0.5) !default;

// Treeview
//
// scss-docs-start treeview-variables
$treeview-transition-collapse: height 0.15s ease !default;
$treeview-row-bg-odd: var(--#{$prefix}gray-200) !default;
$treeview-row-bg-even: var(--#{$prefix}gray-100) !default;
$treeview-text-color: var(--#{$prefix}gray-600) !default;
// scss-docs-end treeview-variables

// Viewport Heights & Widths
//
// scss-docs-start viewport-heights-map
$viewport-heights: (
	25: 25vh,
	50: 50vh,
	75: 75vh,
	100: 100vh,
) !default;
// scss-docs-end viewport-heights-map

// scss-docs-start viewport-widths-map
$viewport-widths: (
	25: 25vw,
	50: 50vw,
	75: 75vw,
	100: 100vw,
) !default;
// scss-docs-end viewport-widths-map

// scss-docs-start sizes-map
$sizes: (
	25: 25%,
	50: 50%,
	75: 75%,
	100: 100%,
	auto: auto,
) !default;
// scss-docs-end sizes-map

// Calendar
//
// scss-docs-start calendar-variables
$calendar-color: var(--#{$prefix}danger) !default;
$calendar-color-rgb: var(--#{$prefix}danger-rgb) !default;
// scss-docs-end calendar-variables

// Footer
//
// scss-docs-start footer-variables
$footer-height: 3.9875rem !default;
$responsive-footer-height: 0.625rem !default;
// scss-docs-end footer-variables

// Avatars dimensions
//
// scss-docs-start avatars-dimension-map
$avatars-dimension: (
	's': toRem(20rem),
	'm': toRem(24rem),
	'l': toRem(28rem),
	'xl': toRem(32rem),
	'2xl': toRem(40rem),
	'3xl': toRem(56rem),
	'4xl': toRem(98rem),
	'5xl': toRem(168rem),
) !default;
// scss-docs-end avatars-dimension-map

$gutters: () !default;
$gutters: map-merge(
	$spacers,
	(
		card: $card-spacer-x * 2,
	)
);

// Popovers
//
// scss-docs-start popover-variables
$popover-bg: var(--#{$prefix}quaternary-bg) !default;
$popover-border-color: var(--#{$prefix}border-color) !default;
$popover-header-bg: var(--#{$prefix}popover-header-bg-global) !default;
$popover-header-color: $headings-color !default;
$popover-body-color: $body-color !default;
$popover-box-shadow: var(--#{$prefix}box-shadow) !default;
$popover-arrow-color: var(--#{$prefix}quaternary-bg) !default;
$popover-arrow-outer-color: rgba(var(--#{$prefix}emphasis-color-rgb), 0.25) !default;
// scss-docs-end popover-variables

// Toasts
//
// scss-docs-start toast-variables
$toast-background-color: var(--#{$prefix}toast-bg-global) !default;
$toast-border-color: var(--#{$prefix}border-color) !default;
$toast-header-color: var(--#{$prefix}gray-600) !default;
$toast-header-background-color: var(--#{$prefix}toast-bg-global) !default;
$toast-header-border-color: rgba($black, 0.05) !default;
$toast-color: var(--#{$prefix}gray-600) !default;
// scss-docs-end toast-variables

// Progress bars
//
// scss-docs-start progress-variables
$progress-bg: var(--#{$prefix}gray-200) !default;
// scss-docs-end progress-variables

// Offcanvas
//
// scss-docs-start offcanvas-variables
$offcanvas-bg-color: var(--#{$prefix}quaternary-bg) !default;
$offcanvas-border-color: var(--#{$prefix}border-color) !default;
// scss-docs-end offcanvas-variables

// Scrollbar
//
// scss-docs-start scrollbar-variables
$scrollbar-bg: var(--#{$prefix}scrollbar-bg) !default;
$simplebar-bg: var(--#{$prefix}simplebar-bg) !default;
// scss-docs-end scrollbar-variables

// Timeline
//
// scss-docs-start timeline-variables
$timeline-vertical-breakpoint: lg !default;
$timeline-zigzag-breakpoint: lg !default;
// scss-docs-end timeline-variables
