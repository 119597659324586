.is-invalid {
  .react-select__control {
    border: 1px solid var(--#{$prefix}danger) !important;
    padding-right: $input-height-inner;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23e63757'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e63757' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right $input-height-inner-quarter center;
    background-size: $input-height-inner-half $input-height-inner-half;

    &.react-select__control--is-focused {
      border-color: var(--#{$prefix}danger) !important;
      box-shadow: 0 0 $input-btn-focus-blur $input-focus-width
        rgba(map-get($theme-colors, 'danger'), $input-btn-focus-color-opacity) !important;
    }
  }
}
.react-select__control {
  min-height: unset !important;
  padding-left: 1rem;
  &.react-select__control--is-focused {
    border-color: $form-select-focus-border-color !important;
    outline: 0;
    box-shadow: $form-select-focus-box-shadow !important;
  }
  background-color: $input-bg !important;
  border: $form-select-border-width solid $form-select-border-color !important;
  @include box-shadow($form-select-box-shadow);

  .react-select__value-container {
    padding: 0;
  }

  .react-select__placeholder {
    color: $input-placeholder-color;
    margin: 0;
  }
  @include hover-focus {
    outline: none !important;
  }
  .react-select__input-container {
    margin: 0;
  }
  .react-select__value-container {
    &.react-select__value-container--has-value {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }
  .react-select__multi-value {
    border-radius: $border-radius;
    background-color: var(--#{$prefix}gray-200) !important;
    .react-select__multi-value__remove {
      // background-color: var(--#{$prefix}gray-300) !important;
      border-radius: 0 2px 2px 0 !important;
      &:hover {
        background-color: unset !important;
        color: var(--#{$prefix}gray-900) !important;
      }
    }
  }
  .react-select__multi-value__label {
    color: var(--#{$prefix}body-color) !important;
    font-size: 12px;
  }
  .react-select__single-value {
    color: var(--#{$prefix}body-color) !important;
  }
}
.react-select__menu {
  background-color: $input-bg !important;
  z-index: $zindex-modal !important;

  .react-select__option {
    padding-left: $card-spacer-x;
    padding-right: $card-spacer-x;
    color: var(--#{$prefix}gray-900) !important;
    margin-bottom: 5px;
  }
}

.react-select__indicator-separator {
  display: none;
}

.react-select__indicator {
  &.react-select__dropdown-indicator {
    svg {
      color: $input-placeholder-color !important;
    }
  }
}

.react-select__option--is-focused {
  background-color: var(--#{$prefix}primary-bg-subtle) !important;
}
.react-select__option--is-selected {
  background-color: var(--#{$prefix}primary) !important;
  color: var(--#{$prefix}black) !important;
}
