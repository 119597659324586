/*-----------------------------------------------
|   Button
-----------------------------------------------*/

/*-----------------------------------------------
|   Button Reveal
-----------------------------------------------*/
.btn-reveal-trigger {
  @include hover-focus {
    .btn-reveal {
      box-shadow: var(--#{$prefix}btn-reveal-hover-shadow);

      &:hover,
      &:focus,
      &:active {
        color: var(--#{$prefix}btn-reveal-hover-color) !important;
      }
    }

    .btn-reveal-sm {
      box-shadow: var(--#{$prefix}box-shadow-sm);
    }
  }

  .btn-reveal {
    box-shadow: none;
    // color: var(--#{$prefix}btn-reveal-color);

    &:active:focus,
    &:active,
    &:focus {
      box-shadow: none;
      color: var(--#{$prefix}btn-reveal-hover-color) !important;
      background-color: var(--#{$prefix}btn-reveal-focus-bg);
      border-color: var(--#{$prefix}btn-reveal-focus-border-color);
    }
  }

  .dropdown-toggle {
    &:after,
    &:before {
      display: none;
    }
  }
}

/*-----------------------------------------------
|   Falcon buttons
-----------------------------------------------*/
$theme-falcon-btn-colors: (
  'primary': var(--#{$prefix}primary),
  'success': var(--#{$prefix}success),
  'info': var(--#{$prefix}info),
  'warning': var(--#{$prefix}warning),
  'danger': var(--#{$prefix}danger),
  'default': var(--#{$prefix}default)
);

@each $color, $value in $theme-falcon-btn-colors {
  .btn-falcon-#{$color} {
    @include falcon-button-variant($color);
  }
}

.btn-tertiary {
  @extend .btn-light;
}

@if $enable-dark-mode {
  @include color-mode(dark, true) {
    .btn-tertiary {
      @extend .btn-dark;
    }
  }
}

/*-----------------------------------------------
|   Brand Outline Buttons
-----------------------------------------------*/
.btn-outline-facebook {
  @include button-outline-variant($facebook);
}
.btn-outline-twitter {
  @include button-outline-variant($twitter);
}
.btn-outline-google-plus {
  @include button-outline-variant($google-plus);
}

/*-----------------------------------------------
|   Outline Buttons
-----------------------------------------------*/
[class*='btn-outline-'] {
  --#{$prefix}btn-box-shadow: none;
}

/*-----------------------------------------------
|   Profile Page Introduction
-----------------------------------------------*/
.btn-intro-collapse {
  .less {
    display: none;
  }
  &[aria-expanded='true'] {
    .less {
      display: inline;
    }
    .full {
      display: none;
    }
  }
}

// Close Button

.btn-close-falcon {
  cursor: pointer;
  position: relative;
  // width: 100%;
  // height: 100%;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  &:after,
  &:before {
    position: absolute;
    left: 12px;
    top: 4px;
    content: ' ';
    height: 100%;
    width: 2px;
    background-color: var(--#{$prefix}gray-700);
  }

  &:after {
    transform: rotate(-45deg);
  }
  &:before {
    transform: rotate(45deg);
  }
}

.btn {
  --#{$prefix}btn-border-width: var(--#{$prefix}border-width);
}
.btn-link {
  --#{$prefix}btn-active-color: var(--#{$prefix}link-hover-color);
}
// disabled btn box shadow
.btn {
  &:disabled,
  &.disabled {
    @include box-shadow(var(--#{$prefix}btn-box-shadow));
  }
}
