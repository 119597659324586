.slick-slider {
  @include hover-focus {
    .slick-arrow {
      opacity: 0.5;
    }
  }
  .slick-arrow {
    position: relative;
    cursor: pointer;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    background-color: var(--#{$prefix}slick-arrow-bg) !important;
    transition: opacity 0.4s ease-in-out;
    box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
    color: var(--#{$prefix}gray-700);
    opacity: 0;
    z-index: 999;
    &:before {
      display: none;
    }

    @include hover-focus {
      opacity: 1;
    }
  }
  .slick-next,
  .slick-prev {
    position: absolute;
    font-size: 0;
    color: transparent;
    border: none;
    &:after {
      content: '';
      position: absolute;
      height: 0.7rem;
      width: 0.7rem;
      left: 50%;
      top: 50%;
      background-color: transparent;
      border-right: 2px solid var(--#{$prefix}gray-700);
      border-top: 2px solid var(--#{$prefix}gray-700);
    }
  }

  .slick-next:after {
    transform: translate3d(-60%, -50%, 0) rotate(45deg);
  }
  .slick-prev:after {
    transform: translate3d(-40%, -50%, 0) rotate(-135deg);
  }

  &.slick-slider-arrow-inner {
    position: relative;
    .slick-next,
    .slick-prev {
      transform: translateY(-50%);
      top: 50%;
    }
    .slick-next {
      right: 1rem;
    }
    .slick-prev {
      left: 1rem;
    }
  }
}

.product-slider {
  position: relative;
  // @include media-breakpoint-up(lg) {
  //   height: calc(100% - 3.5625rem);
  // }

  // @include media-breakpoint-up(xl) {
  //   height: calc(100% - 3.3125rem);
  // }

  // @include media-breakpoint-up(xxl) {
  //   height: calc(100% - 4.75rem);
  // }

  .slick-track {
    padding: 0 !important;
  }
}

.product-image-slider.full-height-slider {
  .slick-list {
    .slick-track {
      padding: 0 !important;
    }
  }
}
.full-height-slider {
  .slick-list {
    height: 100%;

    .slick-track {
      height: 100%;

      .slick-slide {
        height: 100%;
      }

      .slick-slide > div {
        height: 100%;
      }
    }
  }
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;

  .slick-track {
    display: flex;

    .slick-slide {
      height: auto !important;
    }
  }
}

.similar-courses-slider {
  .slick-list {
    .slick-track {
      gap: 10px;
      padding: 20px 0;

      .slick-slide {
        height: auto !important;
      }
    }
  }
}
