/*-----------------------------------------------
|   Object fit and Z-index
-----------------------------------------------*/
.fit-cover {
  object-fit: cover;
}

/*-----------------------------------------------
|   Sidebar
-----------------------------------------------*/
.sticky-sidebar {
  position: sticky;
  z-index: $zindex-sticky;
  top: calc($top-nav-height + 1rem);
}

.top-navbar-height {
  top: $top-nav-height;
}

/*-----------------------------------------------
|   Custom circle
-----------------------------------------------*/
.circle-dashed {
  border: 1px dashed var(--#{$prefix}primary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
}

/*-----------------------------------------------
|   Miscellaneous
-----------------------------------------------*/

.hover-text-decoration-none {
  @include hover-focus {
    text-decoration: none;
  }
}
.resize-none {
  resize: none;
}

.collapsed .collapse-icon {
  transition: $transition-base;
  transform: rotate(0deg);
}
.collapse-icon {
  transition: $transition-base;
  transform: rotate(90deg);
}

[data-dismiss='dropdown'],
[data-bs-offset-top],
[data-bs-toggle='collapse'],
[data-bs-toggle='tooltip'],
[data-bs-toggle='popover'] {
  * {
    pointer-events: none;
  }
}

/*-----------------------------------------------
|   Outline
-----------------------------------------------*/

.outline-none {
  outline: none;
  box-shadow: none;
  @include hover-focus {
    outline: none;
    box-shadow: none;
  }
}

/*-----------------------------------------------
|   Vertical Line (used in kanban header)
-----------------------------------------------*/
.vertical-line {
  &:after {
    position: absolute;
    content: '';
    height: 75%;
    width: 1px;
    background: var(--#{$prefix}gray-300);
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &.vertical-line-400 {
    &:after {
      background-color: var(--#{$prefix}gray-400);
    }
  }
}

/* -------------------------------------------------------------------------- */
/*                                 Transition                                 */
/* -------------------------------------------------------------------------- */

.transition-base {
  transition: $transition-base;
}
.transition-none {
  transition: none;
}

.fsp-75 {
  font-size: 75%;
}

/* -------------------------------------------------------------------------- */
/*                                    Width                                   */
/* -------------------------------------------------------------------------- */

// used in chat
.min-w-0 {
  min-width: 0;
}

/* -------------------------------------------------------------------------- */
/*                                Terms sidebar                               */
/* -------------------------------------------------------------------------- */

.terms-sidebar {
  .nav-item {
    .nav-link.active {
      color: var(--#{$prefix}primary);
    }
    .nav-link {
      color: var(--#{$prefix}gray-500);
      font-weight: $font-weight-medium;
      font-family: $font-family-sans-serif;
    }
  }
}

.divider-content-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding-left: map-get($spacers, 2);
  padding-right: map-get($spacers, 2);
  background-color: var(--#{$prefix}emphasis-bg);
  font-family: $font-family-sans-serif;
  font-size: map-get($font-sizes, 10);
  color: var(--#{$prefix}gray-500);
  white-space: nowrap;
}

.btn-check[data-theme-control]:checked + label {
  display: none;
}
/* -------------------------------------------------------------------------- */
/*                                    Height                                  */
/* -------------------------------------------------------------------------- */
.content {
  min-height: 100vh;
  position: relative;
  padding-bottom: 6.375rem;
  @include media-breakpoint-up(sm) {
    padding-bottom: $footer-height;
  }
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

/* -------------------------------------------------------------------------- */
/*                                    Email                                   */
/* -------------------------------------------------------------------------- */

.email-row-actions {
  top: 0.3125rem;
}

.progress-bar {
  @extend .rounded;
}

//////////////////////////////////////////
.rc-slider {
  position: relative;
  width: 100%;
  height: 14px;
  padding: 5px 0;
  border-radius: 6px;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #e9e9e9;
  border-radius: 6px;
}
.rc-slider-track {
  position: absolute;
  height: 4px;
  background-color: tint(#2db7f5, 60%);
  border-radius: 6px;
}
.rc-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 2px tint(#2db7f5, 50%);
  border-radius: 50%;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  opacity: 0.8;
  touch-action: pan-x;
}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: tint(#2db7f5, 20%);
  box-shadow: 0 0 0 5px tint(#2db7f5, 50%);
}
.rc-slider-handle:focus {
  outline: none;
  box-shadow: none;
}
.rc-slider-handle:focus-visible {
  border-color: #2db7f5;
  box-shadow: 0 0 0 3px tint(#2db7f5, 50%);
}
.rc-slider-handle-click-focused:focus {
  border-color: tint(#2db7f5, 50%);
  box-shadow: unset;
}
.rc-slider-handle:hover {
  border-color: tint(#2db7f5, 20%);
}
.rc-slider-handle:active {
  border-color: tint(#2db7f5, 20%);
  box-shadow: 0 0 5px tint(#2db7f5, 20%);
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px;
}
.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: #999;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}
.rc-slider-mark-text-active {
  color: #666;
}
.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
  pointer-events: none;
}
.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  background-color: #fff;
  border: 2px solid #e9e9e9;
  border-radius: 50%;
  cursor: pointer;
}
.rc-slider-dot-active {
  border-color: tint(#2db7f5, 50%);
}
.rc-slider-dot-reverse {
  margin-right: -4px;
}
.rc-slider-disabled {
  background-color: #e9e9e9;
}
.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}
.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  background-color: #fff;
  border-color: #ccc;
  box-shadow: none;
  cursor: not-allowed;
}
.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}
.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;
}
.rc-slider-vertical .rc-slider-rail {
  width: 4px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-track {
  bottom: 0;
  left: 5px;
  width: 4px;
}
.rc-slider-vertical .rc-slider-handle {
  margin-top: 0;
  margin-left: -5px;
  touch-action: pan-y;
}
.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-step {
  width: 4px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-dot {
  margin-left: -2px;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  display: block !important;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-leave {
  display: block !important;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  animation-name: rcSliderTooltipZoomDownIn;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  animation-name: rcSliderTooltipZoomDownOut;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  transform: scale(0, 0);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.rc-slider-tooltip-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes rcSliderTooltipZoomDownIn {
  0% {
    transform: scale(0, 0);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1, 1);
    transform-origin: 50% 100%;
  }
}
@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform: scale(1, 1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0, 0);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
.rc-slider-tooltip {
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip-hidden {
  display: none;
}
.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0;
}
.rc-slider-tooltip-inner {
  min-width: 24px;
  height: 24px;
  padding: 6px 2px;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  background-color: tint(#666, 4%);
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}
.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: tint(#666, 4%);
}
